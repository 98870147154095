import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import action
import { setTradeTheme } from "../../actions/commonAction";

// import lib
import isLogin from "../../lib/isLogin";
import {
    getTheme,
    changeTradeTheme as changeTradeThemeLocal,
} from "../../lib/localStorage";

import { setAccountData } from "../../actions/users";

const ConditionRoute = ({ component: Component, layout: Layout, auth, type, ...rest }) => {
    const history = useHistory;
    const dispatch = useDispatch();
    const account = useSelector((state) => state.account);

    return (
        <Route
            {...rest}
            render={(props) => {
                if ([
                    "/recover-password",
                    "/",
                    "/marketlist",
                    "/bank",
                    "/privacy-policy",
                    "/staking-list",
                    "/copy-trading",
                    "/copy-trader-info",
                    "/copy-trade-myaccount",
                    "/copy-trading-setting",
                    "/peertopeer",
                    "/register",
                    "/login",
                    "/spot/:tikerRoot?",
                    "/p2p",
                    "/dashboard",
                    "/postmyad",
                    "/wallet",
                    "/P2pwallet",
                    "/copy-wallet",
                    "/notification",
                    "/tradenotification",
                    "/profile",
                    "/kyc",
                    "/settings",
                    "/history",
                    "/trade-history",
                    "/myads",
                    "/chat/:id",
                    "/contact",
                    "/about",
                    "/support-ticket",
                    "/support",
                    "/term",
                    "/privacy",
                    "/fees",
                    "/faq",
                    "/terms",
                    ].includes(props.match.path)) {
                    setTradeTheme(dispatch, getTheme());
                } else {
                    setTradeTheme(dispatch, "light");
                }

                // else
                if (type == "auth" && isLogin() == true) {
                    return <Redirect to="/profile" />;
                } else if (type == "private" && isLogin() != true) {
                    return <Redirect to="/login" />;
                }

                return <Component {...props} />;
            }}
        />
    );
};

export default ConditionRoute;
