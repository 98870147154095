// import config
import axios from "../config/axios";
//
import { setAccountData } from "./users";

// import constant
import { SET_COPYTRADE_SETTING } from "../constant";

export const getCopyTradeSettings = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/getCopyTradeSettings`,
        });
        dispatch(setCopyTradeSetting(respData.data.result));
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const setCopyTradeSetting = (data) => {
    return {
        type: SET_COPYTRADE_SETTING,
        data,
    };
};

export const applyBecomeTrader = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/applyBecomeTrader`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const getTradersList = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getTradersList`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            result: respData.data.result,
            total_count: respData.data.total_count,
            tradersCount: respData.data.tradersCount,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const getTraderInfo = async (id) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/getTraderInfo/${id}`,
        });
        return {
            status: respData.data.status,
            loading: false,
            result: respData.data.result,
            tradersCount: respData.data.tradersCount,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const getFollowerSetting = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getFollowerSetting`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const validateCopyTradeForm = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/validateCopyTradeForm`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            errors: err.response.data.errors,
        };
    }
};

export const saveCopyTradeFollowers = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/saveCopyTradeFollowers`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const unfollowTrader = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/unfollowTrader`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const traderUnfollow = async (data, dispatch) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/traderUnfollow`,
            data,
        });
        dispatch(setAccountData(respData.data.result));
        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};

export const getFollowerDetails = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getFollowerDetails`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const getMyTradersList = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getMyTradersList`,
            data,
        });
        return {
            status: respData.data.status,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};

export const getPLHistoryList = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getPLHistoryList`,
            data,
        });
        return {
            status: respData.data.status,
            result: respData.data.result,
            totalCount: respData.data.totalCount
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message
        };
    }
};

export const getFollowersHistoryList = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getFollowersHistoryList`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const getCopyTradeHistoryList = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getCopyTradeHistoryList`,
            data,
        });
        return {
            status: respData.data.status,
            result: respData.data.result,
            totalCount: respData.data.totalCount
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};

export const updateTraderSettings = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/updateTraderSettings`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            error: err.response.data.errors,
            message: err.response.data.message,
        };
    }
};

export const badgeRequirements = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/badgeRequirements`,
        });
        return {
            status: respData.data.status,
            loading: false,
            result: respData.data.result
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const notifyTrader = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/notifyTrader`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false
        };
    }
};

export const getPlanHistoryList = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getPlanHistoryList`,
            data,
        });
        return {
            status: respData.data.status,
            loading: false,
            result: respData.data.result,
            adminFee: respData.data.adminFee,
            adminFeeEach: respData.data.adminFeeEach,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const canFollow = async (traderId) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/canFollow`,
            data: { traderId }
        });
        return { status: true };
    } catch (err) {
        return { status: false, message: err.response.data.message };
    }
};
