// import config
import axios, { setAuthorization, removeAuthorization ,handleResp} from "../config/axios";
import config from "../config";
import { removeAuthToken, setAuthToken } from "../lib/localStorage";

// import action
import { setTradeTheme } from "./commonAction";
import { setTradeTheme as setTradeThemeLocal } from "../lib/localStorage";

// import constant
import {
  SET_USER_ACCOUNT,
  UPDATE_USER_ACCOUNT,
  SET_BANK_DETAIL,
  SET_BANK_FORM,
  SET_USER_SETTING,
  REMOVE_AUTHENTICATION,
  SET_UPI_DETAIL,
  SET_QR_DETAIL,
  SET_QR_FORM,
  SET_UPI_FORM,
} from "../constant";

// import action
import { decodeJwt } from "./jsonWebToken";
import { getAssetData } from "./walletAction";

import isLogin from "../lib/isLogin";

export const sendOtp = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/sendOtp`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const createUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/register`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      userToken: respData.data.userToken,
      isMobile: respData.data.isMobile,
    };
  } catch (err) {
    console.log("errerrerrerrerrerr", err.response.data);
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      showmotp: err.response.data.showmotp,
    };
  }
};

export const createUserPhone = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/registerphone`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const userEmailActivation = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/confirm-mail`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const login = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/login`,
      data,
    });
    if (respData.data.status == "TWO_FA") {
      return {
        status: "TWO_FA",
        loading: false,
        message: respData.data.message,
      };
    }

    setAuthorization(respData.data.token);
    decodeJwt(respData.data.token, dispatch);
    setAuthToken(respData.data.token);
    dispatch(setAccountData(respData.data.result));
    getAssetData(dispatch);
    setTradeThemeLocal(respData.data.userSetting.theme);

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
    // }
  } catch (err) {
    try {
      return {
        status: "failed",
        loading: false,
        message: err.response.data.message,
        error: err.response.data.errors,
      };
    } catch (err) {
      return {
        status: "failed",
        loading: false,
      };
    }
  }
};

export const editUserProfile = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/userProfile`,
      data,
    });
    dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const viewUserProfile = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/userProfile`,
    });
    dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const setAccountData = (data) => {
  return {
    type: SET_USER_ACCOUNT,
    data: {
      userId: data.userId,
      uniqueId: data.uniqueId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      blockNo: data.blockNo,
      address: data.address,
      city: data.city,
      state: data.state,
      country: data.country,
      postalCode: data.postalCode,
      emailStatus: data.emailStatus,
      phoneStatus: data.phoneStatus,
      phoneCode: data.phoneCode,
      phoneNo: data.phoneNo,
      type: data.type,
      twoFAStatus: data.twoFAStatus,
      createAt: data.createAt,
      loginHistory: data.loginHistory,
      bankDetail: data.bankDetail,
      dob: data.dob,
      kycStatus: data.kycStatus,
      profilePic: data.profilePic,
      traderFollowMode: data.traderFollowMode,
      cityName: data.cityName,
      stateName: data.stateName,
      countryName: data.countryName,
      maxTraders: data.maxTraders,
      tradeDiscount: data.tradeDiscount,
      isTrader: data.isTrader,
      isIndian: data.isIndian,
    },
  };
};

export const forgotPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/forgotPassword`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      userToken: respData.data.userToken,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const resetPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/resetPassword`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const resetPasswordexpiry = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/resetPasswordexpiry`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getBankDetail = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/bankdetail`,
    });
    dispatch(
      setBankDetail({
        result: respData.data.result,
      })
    );
    return true;
  } catch (err) {
    handleResp(err, 'error')
    return false;
  }
};

export const updateBankDetail = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/bankdetail`,
      data,
    });

    dispatch(
      setBankDetail({
        formType: "",
        formDisable: true,
        result: respData.data.result,
      })
    );
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const setPrimaryBank = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "patch",
      url: `/api/bankdetail`,
      data,
    });

    dispatch(
      setBankDetail({
        result: respData.data.result,
      })
    );
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const deleteBankDetail = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/bankdetail`,
      data,
    });

    dispatch(
      setBankDetail({
        result: respData.data.result,
        formType: "",
        formDisable: true,
        editRecord: {},
      })
    );
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const setBankDetail = (data) => {
  return {
    type: SET_BANK_DETAIL,
    data,
  };
};

export const setBankForm = (data) => {
  return {
    type: SET_BANK_FORM,
    data,
  };
};

export const changePassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/changePassword`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const get2faCode = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/security/2fa`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const update2faCode = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/security/2fa`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const disabled2faCode = async (data) => {
  try {
    let respData = await axios({
      method: "patch",
      url: `/api/security/2fa`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getUserSetting = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/userSetting`,
    });
    dispatch(setUserSetting(respData.data.result));
    return true;
  } catch (err) {
    handleResp(err, 'error')
    return false;
  }
};

export const setUserSetting = (data) => {
  return {
    type: SET_USER_SETTING,
    data,
  };
};

export const editUserSetting = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/userSetting`,
      data,
    });

    setTradeThemeLocal(respData.data.result.theme);
    dispatch(setUserSetting(respData.data.result));
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const upgradeUser = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/upgradeUser`,
      data,
    });
    dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const changeNewPhone = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/phoneChange`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const verifyNewPhone = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/phoneChange`,
      data,
    });
    dispatch({
      type: UPDATE_USER_ACCOUNT,
      data: respData.data.result,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const phoneChangeOtp = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/phoneChangeOtp`,
      data,
    });
    dispatch({
      type: UPDATE_USER_ACCOUNT,
      data: respData.data.result,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const editEmail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/emailChange`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      userToken: respData.data.userToken,
      isMobile: respData.data.isMobile,
      isComplete: respData.data.isComplete,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const addemail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/addemail`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const verifyOldEmail = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/emailChange`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const verifyNewEmail = async (data) => {
  try {
    let respData = await axios({
      method: "patch",
      url: `/api/emailChange`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

/* ********************** */
export const verifyIpAddress = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/verifyIpAddress`,
      data,
    });
    await setAuthToken(respData.data.token);
    // decodeJwt(respData.data.token, dispatch)
    // await dispatch(setCurrentUser(respData.data.result))
    // await setInitialCall();
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      messages: respData.data.messages,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCurrentUser = async (token, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/currentUser`,
      headers: {
        Authorization: token,
      },
    });
    // dispatch(setCurrentUser(respData.data.result))
    return true;
  } catch (err) {
    handleResp(err, 'error')
    return false;
  }
};

export const forgotPasswordMobile = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/forgotPassword/mobile`,
      data,
    });
    return {
      status: "success",
      loading: false,
      messages: respData.data.messages,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const logout = (history, dispatch) => {
  removeAuthToken();
  removeAuthorization();
  window.location.href = "/login";
  dispatch({
    type: REMOVE_AUTHENTICATION,
    authData: {
      isAuth: false,
    },
  });
};

export const editProfile = async (data) => {
  try {
    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("profileImage", data.profileImage);

    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/api/account/profile`,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: localStorage.user_token,
      },
      data: formData,
    });

    return {
      status: "success",
      loading: false,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getGeoInfoData = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.getGeoInfo}`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getUserDetail = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/account/userProfile`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const sentOTP = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/sent-otp`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getReferralHistory = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/referralHistory`,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const newsLetters = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/newLetters/`,
      data: data,
    });
    console.log(respData, "ssssssssssss");
    return {
      status: respData.data.status,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      status: false,
      error: err.response.data.errors,
    };
  }
};

export const conformresetPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/resetPassword/`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getUPIDetail = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/upidetail`,
    });
    dispatch(
      setUPIDetail({
        result: respData.data.result.upiDetails,
      })
    );
    return true;
  } catch (err) {
    handleResp(err, 'error')
    return false;
  }
};

export const getQRDetail = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/qrdetail`,
    });

    dispatch(
      setQRDetail({
        result: respData.data.result.qrDetails,
      })
    );
    return true;
  } catch (err) {
    handleResp(err, 'error')
    return false;
  }
};

export const updateUPIDetail = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/upidetail`,
      data,
    });
    dispatch(
      setUPIDetail({
        formType: "",
        formDisable: false,
        result: respData.data.result,
      })
    );
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const updateQRDetail = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/qrdetail`,
      data,
    });

    dispatch(
      setQRDetail({
        formType: "",
        formDisable: false,
        result: respData.data.result,
      })
    );
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const setPrimaryUPI = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/primaryupi`,
      data,
    });
    dispatch(
      setUPIDetail({
        result: respData.data.result,
      })
    );
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    console.log("err---", err);
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const setPrimaryQR = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/primaryqr`,
      data,
    });

    dispatch(
      setQRDetail({
        result: respData.data.result,
      })
    );
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    console.log("err---", err);
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};
export const deleteUPIDetail = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/upidetail`,
      data,
    });

    dispatch(
      setUPIDetail({
        result: respData.data.result,
        formType: "",
        formDisable: true,
        editRecord: {},
      })
    );
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const deleteQRDetail = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/qrdetail`,
      data,
    });

    dispatch(
      setQRDetail({
        result: respData.data.result,
        formType: "",
        formDisable: true,
        editRecord: {},
      })
    );
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const setUPIForm = (data) => {
  return {
    type: SET_UPI_FORM,
    data,
  };
};
export const setQRDetail = (data) => {
  return {
    type: SET_QR_DETAIL,
    data,
  };
};
export const setQRForm = (data) => {
  return {
    type: SET_QR_FORM,
    data,
  };
};

export const setUPIDetail = (data) => {
  return {
    type: SET_UPI_DETAIL,
    data,
  };
};

export const newEmailVerificationApi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/newEmailVerification`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};


export const auth = (data) => {
  if((data==401) && (isLogin() == true)){
   removeAuthToken();
   removeAuthorization();
   window.location.reload();
  }
  return
 };

 export const EmailverifyOtp = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/EmailverifyOtp`,
      data,
    });

    return {
      status: "success",
      message: respData.data.message,
      loading: false,
      isVerify:respData.data.isVerify
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
