// import package
import React, { useEffect, useState, Suspense, lazy, useContext } from "react";
import {
  Switch,
  BrowserRouter,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";

// import component
import ConditionRoute from "./components/Route/ConditionRoute";
import i18n from "./components/i18next/i18n";
import HelperRoute from "./components/Route/HelperRoute";

// import Context
import SocketContext from "./components/Context/SocketContext";

// import action
import { decodeJwt } from "./actions/jsonWebToken";
import { logout } from "./actions/users";
import { maintenanceCheck } from "./actions/commonAction";

// import config
import { socket } from "./config/socketConnectivity";

// import lib
import store from "./store";
import isLogin from "./lib/isLogin";
import { getAuthToken } from "./lib/localStorage";
import { createBrowserHistory } from "history";

//idle timer
import { useIdleTimer } from "react-idle-timer";

// pages for this product
const HomePage = React.lazy(() => import("./pages/HomePage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const Register = React.lazy(() => import("./pages/register"));
const ForgotPassword = React.lazy(() => import("./pages/forgot-password"));
const EmailVerification = React.lazy(() => import("./pages/EmailVerification"));
const ProfilePage = React.lazy(() => import("./pages/profile"));
const BankAccount = React.lazy(() => import("./pages/BankAccount"));

const SettingPage = React.lazy(() => import("./pages/settings"));
const Kyc = React.lazy(() => import("./pages/kyc"));
const DashboardPage = React.lazy(() => import("./pages/DashboardPage"));
const WalletPage = React.lazy(() => import("./pages/WalletPage"));
const Staking = React.lazy(() => import("./pages/stakingComingSoon"));
const Spot = React.lazy(() => import("./pages/spot"));
const Derivative = React.lazy(() => import("./pages/derivative"));
const ResetPasswordPage = React.lazy(() => import("./pages/ResetPasswordPage"));
const AboutUsPage = React.lazy(() => import("./pages/AboutUsPage"));
const FaqPage = React.lazy(() => import("./pages/FaqPage"));
const PressPage = React.lazy(() => import("./pages/PressPage"));
const InvestorsPage = React.lazy(() => import("./pages/InvestorsPage"));
const TermsPage = React.lazy(() => import("./pages/TermsPage"));
const PrivacyPolicyPage = React.lazy(() => import("./pages/PrivacyPolicyPage"));
const ApiMgmtPage = React.lazy(() => import("./pages/ApiMgmtPage"));
const TradeNotification = React.lazy(() =>
  import("./pages/tradenotificationlist")
);
const MarketList = React.lazy(() => import("./pages/marketTableList"));

// import CMS Page
const BusinessPage = React.lazy(() => import("./pages/cms/BusinessPage"));
const TokenListPage = React.lazy(() => import("./pages/cms/TokenListPage"));
const ApiPage = React.lazy(() => import("./pages/cms/ApiPage"));
const FeesPage = React.lazy(() => import("./pages/cms/FeesPage"));
const SecurityPage = React.lazy(() => import("./pages/cms/SecurityPage"));
const StakingPage = React.lazy(() => import("./pages/cms/StakingPage"));
const ChartPage = React.lazy(() => import("./pages/chart"));
const HistoryPage = React.lazy(() => import("./pages/history"));
const TradeHistory = React.lazy(() => import("./pages/tradeHistory"));
const SupportPage = React.lazy(() => import("./pages/SupportPage"));
const Launchpad = React.lazy(() => import("./pages/launchpad"));
const LaunchpadDetails = React.lazy(() => import("./pages/launchpaddetails"));
const P2pwallet = React.lazy(() => import("./pages/P2pwallet"));
const CopyWallet = React.lazy(() => import("./pages/CopyWallet"));

// Copy Trading
const CopyOrderPlace = React.lazy(() => import("./pages/CopyTrade"));
const CopyTrading = React.lazy(() => import("./pages/copy-trading"));
const CopyTradingSettingLayout = React.lazy(() =>
  import("./pages/copy-trading-setting")
);
const CopyTradingMyAccountLayout = React.lazy(() =>
  import("./pages/copy-trade-myaccount")
);
const CopyTraderInfoLayout = React.lazy(() =>
  import("./pages/copy-trader-info")
);
const Contact = React.lazy(() => import("./pages/ContactUs"));
const Badge = React.lazy(() => import("./pages/badge"));
const Subscription = React.lazy(() => import("./pages/subscription"));

// P2P Pages
const P2P = React.lazy(() => import("./pages/p2p"));
const Postmyad = React.lazy(() => import("./pages/postmyad"));
const Peertopeer = React.lazy(() => import("./pages/peertopeer"));
const MyAds = React.lazy(() => import("./components/MyAds"));
const Chat = React.lazy(() => import("./pages/p2pchat"));
const P2PTradeHistory = React.lazy(() => import("./pages/p2pTradeHistory"));

const Maintenance = React.lazy(() => import("./pages/maintenance/maintenance"));

const App = () => {
  const socketContext = useContext(SocketContext);
  const history = useHistory();
  const { isAuth } = store.getState().auth;
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(async () => {
    if (isAuth != true && isLogin()) {
      decodeJwt(getAuthToken(), store.dispatch);
    }

    setMaintenanceMode(await maintenanceCheck());
  }, []);

  useEffect(() => {
    socket.on("maintenanceMode", async (maintenance) => {
      setMaintenanceMode(maintenance);
    });
    return () => socket.off("maintenanceMode");
  }, [socket]);

  useEffect(() => {
    if(!maintenanceMode && isAuth)
      logout(history, store.dispatch);
  }, [maintenanceMode]);

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    console.log("remaining", getRemainingTime());
    if (
      getRemainingTime() == 0 &&
      localStorage.getItem("set_keep") != "false"
    ) {
      logout(history, store.dispatch);
    }
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    // console.log("user did something", event);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60 * 12, // 12 hour
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter basename="/">
          <Suspense
            fallback={
              <div class="site_loader">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            }
          >
            <SocketContext.Provider value={{ socket }}>
              {maintenanceMode ? (
                <>
                  <Route exact path="/">
                    <Maintenance />
                  </Route>
                  <Redirect to="/" />
                </>
              ) : (
                <>
                  <ToastContainer />
                  <HelperRoute />
                  <Switch>
                    <ConditionRoute
                      exact
                      path="/"
                      component={HomePage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/home"
                      component={HomePage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/staking-list"
                      component={Staking}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/launchpaddetails"
                      component={LaunchpadDetails}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/launchpad"
                      component={Launchpad}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/history"
                      component={HistoryPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/support"
                      component={SupportPage}
                      type={"public"}
                    />

                    <ConditionRoute
                      exact
                      path="/login"
                      component={LoginPage}
                      type={"auth"}
                    />
                    <ConditionRoute
                      exact
                      path="/register"
                      component={Register}
                      type={"auth"}
                    />
                    <ConditionRoute
                      exact
                      path="/recover-password"
                      component={ForgotPassword}
                      type={"auth"}
                    />
                    <ConditionRoute
                      exact
                      path="/reset-password/:authToken"
                      component={ResetPasswordPage}
                      type={"auth"}
                    />
                    <ConditionRoute
                      exact
                      path="/verify-resetPassword/:authToken"
                      component={EmailVerification}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/Newemail-verification/:authToken"
                      component={EmailVerification}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/marketlist"
                      component={MarketList}
                      type={"public"}
                    />

                    <ConditionRoute
                      exact
                      path="/profile"
                      component={ProfilePage}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/bank"
                      component={BankAccount}
                      type={"private"}
                    />

                    <ConditionRoute
                      exact
                      path="/settings"
                      component={SettingPage}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/dashboard"
                      component={DashboardPage}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/wallet"
                      component={WalletPage}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/kyc"
                      component={Kyc}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/api-management"
                      component={ApiMgmtPage}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/spot/:tikerRoot?"
                      component={Spot}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/derivative/:tikerRoot?"
                      component={Derivative}
                      type={"public"}
                    />

                    <ConditionRoute
                      exact
                      path="/chart"
                      component={ChartPage}
                      type={"public"}
                    />

                    <ConditionRoute
                      exact
                      path="/P2pwallet"
                      component={P2pwallet}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/postmyad"
                      component={Postmyad}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/peertopeer"
                      component={Peertopeer}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/myads"
                      component={MyAds}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/p2pTradeHistory"
                      component={P2PTradeHistory}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/chat/:id"
                      component={Chat}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/copy-wallet"
                      component={CopyWallet}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/copy-trader-info"
                      component={CopyTraderInfoLayout}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/copy-trade-myaccount"
                      component={CopyTradingMyAccountLayout}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/copy-trading-setting"
                      component={CopyTradingSettingLayout}
                      type={"private"}
                    />
                    <ConditionRoute
                      exact
                      path="/copy-trading"
                      component={CopyTrading}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/Copy/:tikerRoot?"
                      component={CopyOrderPlace}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/trade-history"
                      component={TradeHistory}
                      type={"public"}
                    />
                    {/* CMS Page */}
                    <ConditionRoute
                      exact
                      path="/about"
                      component={AboutUsPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/faq"
                      component={FaqPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/press"
                      component={PressPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/investors"
                      component={InvestorsPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/terms"
                      component={TermsPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/privacy-policy"
                      component={PrivacyPolicyPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/business"
                      component={BusinessPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/listing"
                      component={TokenListPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/api"
                      component={ApiPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/fees"
                      component={FeesPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/security"
                      component={SecurityPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/staking"
                      component={StakingPage}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/badge"
                      component={Badge}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/subscription"
                      component={Subscription}
                      type={"private"}
                    />
                    {/* CMS Page */}

                    <ConditionRoute
                      exact
                      path="/contact"
                      component={Contact}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/email-verification/:authToken"
                      component={EmailVerification}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/verify-old-email/:authToken"
                      component={EmailVerification}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/verify-new-email/:authToken"
                      component={EmailVerification}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/withdraw-fiat-verification/:authToken"
                      component={EmailVerification}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/withdraw-coin-verification/:authToken"
                      component={EmailVerification}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/tradenotification"
                      component={TradeNotification}
                      type={"public"}
                    />
                    <ConditionRoute
                      exact
                      path="/maintenance"
                      component={Maintenance}
                      type={"public"}
                    />
                  </Switch>
                </>
              )}
            </SocketContext.Provider>
          </Suspense>
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  );
};

export default App;
