// import constant
import { SET_COPYTRADE_SETTING } from "../constant";

const CopyTradeSetting = (state = {}, action) => {
	switch(action.type) {
		case SET_COPYTRADE_SETTING:
			return { ...state, ...action.data };
		default:
			return state;
	}
};

export default CopyTradeSetting;