export default {
  REGISTER_TITLE_1: "Register your account easily and start trading.",
  ACCEPT_TERMS_MESSAGE: "Please accept the Terms & Conditions of Use",
  RECOVERY_PASSWORD_TITLE:
    "Enter the email address associated with your WHITE CRYPTO account and we'll send you a link to set a new password.",
  EMAIL_NOT_EXISTS: "User account not found",
  SIGN_IN_BUTTON: "Sign In",
  EMAIL_PLACEHOLDER: "Email Address",
  KEEP_SIGN_COMPUTER: "Keep me signed in on this computer",
  "DON'T_HAVE_ACCOUNT": "Don't have an account",
  EMAIL_REQUIRED: "Email field is required",
  EMAIL_INVALID: "Email is invalid",
  FORGOT_PASSWORD: "Forgot password",
  PASSWORD_PLACEHOLDER: "Password",
  PASSWORD_REQUIRED: "Password field is required",
  PASSWORD_INCORRECT: "Password incorrect",
  PASSWORD_MIN_MAX:
    "Password should contain at least one uppercase, at least one lowercase, at least one number, at least one special character and minimum 6 character and maximum 18 character",
  CONFIRM_PASSWORD_MISMATCH: "Passwords must match",
  PASSWORD_CHANGE_SUCCESS: "Password Reset Successfully",
  CURRENT_PASSWORD_REQUIRED: "Current Password field is required",
  CONFIRM_PASSWORD_REQUIRED: "Confirm password field is required",
  UPDATE_PASSWORD: "Update Login Password",
  CURRENT_PASSWORD: "Current Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm New Password",
  REQUIRED: "Required",
  INVALID_CODE: "Invalid Code",
  TERMS_REQUIRED: "Terms field is required",
  PERSONAL_DETAILS: "Personal Details",
  FULL_NAME: "Full Name",
  RESIDENTIAL_ADDRESS: "Residential Address",
  BUILDING_BLOCK: "Building / Block Name",
  ADDRESS: "Address",
  STATE_PROVISION: "State / Province",
  CITY: "City",
  STATE: "Bank State",
  POSTAL_CODE: "Postal Code",
  COUNTRY: "Country",
  EDIT_PROFILE: "Update my Profile",
  KYC_SUBMIT: "Submit for verification",
  SUBMIT: "Submit",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  NAME: "Name",
  PROFILE_PICTURE: "Profile Picture",
  PROFILE_EDIT_SUCCESS: "Thank you, your profile has been updated!",
  SOMETHING_WRONG: "Something went wrong",
  NO_DATA: "There is no data",
  NO_ORDER: "There is no order",
  ORDER_CANCEL: "Your Order cancelled successfully",
  ORDER_ALREADY_COMPLETED: "Your Order already completed",
  ORDER_ALREADY_CANCEL: "Your Order already cancelled",
  REGISTER_BANK_ACCOUNT:
    "Register your bank account so that you can transfer to and from White Crypto.",
  ADD_BANK_ACCOUNT: "Add Bank Account",
  ADD_BANK: "Add Bank",
  MY_BANK_ACCOUNT: "My Bank Accounts",
  DEFAULT: "Default",
  BANK_ACCOUNT: "Bank Account",
  BANK_NAME: "Bank Name",
  ACCOUNT_NO: "Account No",
  ACCOUNT_NUMBER: "Account Number",
  CONFIRM_ACCOUNT_NUMBER: "Confirm Account Number",
  ACCOUNT_NUMBER_EUR: "IBAN Account Number",
  ACCOUNT_HOLDER_NAME: "Account Holder Name",
  ROUTING_NUMBER: "Routing number(s)",
  BIC_SWIFT_CODE: "BIC/SWIFT Code",
  IBAN_CODE: "IFSC Code",
  BANK: "Bank",
  STATUS: "Status",
  TWO_FA: "Two Factor Authentication",
  EDIT: "Edit",
  DELETE: "Delete",
  TWO_FA_MSG: "You have to enable 2FA in order to withdraw fiat funds.",
  BANK_EDIT_SUCCESS: "Bank details updated successfully",
  BANK_ADD_SUCCESS: "Bank details added successfully",
  BANK_DELETE_SUCCESS: "Bank details deleted successfully",
  BANK_SET_PRIMARY_SUCCESS: "Default bank details updated successfully",
  UPDATE: "Update",
  NOTES: "Notes",
  PASSWORD_TITLE: "For security measures, your password should contain:",
  PASSWORD_DESCRIPTION1:
    "A minimum of 6 characters and a maximum of 18 characters.",
  PASSWORD_DESCRIPTION2: "At least one UPPERCASE letter (A, B, X, Y...)",
  PASSWORD_DESCRIPTION5: "At least one LOWERCASE Letter (a, b, x, y...)",
  PASSWORD_DESCRIPTION3: "At least one digit (0, 1,2, 9...)",
  PASSWORD_DESCRIPTION4: "At least one special character (! @ # $ ^ *...)",
  TWO_FA_DESCRIPTION1: `"“Enable” Two Factor Authentication as an additional security measure to protect and withdraw your funds.`,
  PASSWORD_DESCRIPTION6: "At least one special character (! @ # $ ^ *...)",
  TWO_FA_DESCRIPTION: `Enable Two Factor Authentication as an additional security measure, to protect your funds and be able to withdraw. Please use Google Authenticator
    which you can`,
  TWO_FA_DESCRIPTION2: `Please download the Google Authenticator by clicking “Download Here” and scan the QR Code. You will be receiving a 6 digit code, which you need to enter in the 2FA box.
    Write down the 32 digit code on a white paper for security purposes. If incase you forget your 2FA code, with this 32 digit code you will be able to withdraw funds.`,
  TWO_FA_DESCRIPTION3:
    "Right down the 32 digit code in a white paper for your security if you forgot the 2FA code to withdraw funds",
  DOWNLOAD_HERE: "Download Here",
  HOW_ENABLE: "How to Enable",
  ENABLE: "Enable 2FA",
  DISABLE: "Disable 2FA",
  ENTER_TWO_FA_CODE: "Enter 6 Digit 2FA Code",
  TWO_FA_CODE: "Your 32 Digit Security Code",
  SCAN_QR_CODE: "Scan the QR Code",
  DISABLED: "Disabled",
  ENABLED: "Enabled",
  TWO_FA_ENABLE_SUCCESS: "2FA enabled successfully",
  TWO_FA_DISABLE_SUCCESS: "2FA disabled successfully",
  EDIT_SETTING_SUCCESS: "Setting updated successfully",
  IDENTITY_DOCUMENT_VERIFIED: "Your identity document has been verified",
  IDENTITY_DOCUMENT_SUBMITTED: "Your ID document has been uploaded correctly",
  IDENTITY_DOCUMENT: "Identity Document",
  IDENTIFICATION_DOCUMENT: "Identification Document",
  SELECTED_ID_NUMBER: "Document ID number",
  FRONT_SIDE: "Front Side Image",
  PICTURE_SCAN: "Picture or scan showing the 4 corners",
  MAX_1MB_IMG: "Max 1MB in jpg/png",
  BACK_SIDE: "Back Side Image",
  RECAPTCHA_REQUIRED: "ReCAPTCHA Required",
  SELFIE_SELECTED_ID: "Selfie with Selected ID",
  IDENTITY_DOCUMENT_TITLE1:
    "Here you can verify your identity by uploading pictures of the government approved Identification Document.",
  IDENTITY_DOCUMENT_DESCRIPTION1:
    "All images and text should be clearly visible.",
  IDENTITY_DOCUMENT_DESCRIPTION2:
    "Your status will not be verified if the text is unclear or if the face is unlcear or if the image is blur.",
  IDENTITY_DOCUMENT_DESCRIPTION3:
    "The live selfie with your ID must clearly show your face along with you holding the selected ID.",
  IDENTITY_DOCUMENT_DESCRIPTION4:
    "If you want to change your identity document please contact our",
  IDENTITY_HINT1: "Max. 9MB size, jpg, png, pdf allowed",
  IDENTITY_HINT2: "Max 9MB in jpg/png/pdf",
  HERE: "Here",
  IDENTITY_DOCUMENT_VERIFIED: "Your ID document has been uploaded correctly",
  HAVE_CPOIED_CODE: "I have copied 32 Security code",
  SUPPORT_TEAM: "support team",
  ADDRESS_DOCUMENT_TITLE1: "Proof of Residential Address",
  ADDRESS_DOCUMENT_TITLE2: "Address Proof Document",
  ADDRESS_DOCUMENT_TITLE3: "Front Side Image",
  ADDRESS_DOCUMENT_DESCRIPTION1:
    "- The document must clearly indicate your full name and full address.",
  ADDRESS_DOCUMENT_DESCRIPTION2:
    "- The document should NOT be older than 3 months.",
  ADDRESS_DOCUMENT_DESCRIPTION3:
    "- Approved utility bill includes electricity bill, gas bill, landline bill and internet bill. Mobile bill is not an acceptable utility bill.",
  ADDRESS_DOCUMENT_DESCRIPTION4:
    "Utility Bill (Approved utility bills: electricity bill, gas bill, landline phone, internet bill). Mobile phone not accepted.",
  ADDRESS_DOCUMENT_DESCRIPTION5:
    "If you want to change your Address document please contact our",
  ADDRESS_DOCUMENT_VERIFIED: "Your proof of address has been submited",
  DRIVING_LICENSE: "Driving License",
  UTILITY_BILL: "Utility Bill",
  BANK_STATEMENT: "Bank statement",
  CREDIT_CARD_STATEMENT: "Credit card statement",
  MUNICIPALITY_REGISTRATION: "Municipality Registration",
  GOVERNMENT_ISSUED_ID: "Government issued ID",
  AADHAR_CARD: "AADHAR CARD",
  PAN_Card_ID: "PAN Card",
  PASSPORT: "Passport",
  PANCARD: "Pancard",
  GAS_BILL: "Gas Bill",
  AADHAR_CARD: "Aadhar Card",
  TOO_LARGE: "Too large",
  SELECT_IMAGE: "Select Image",
  INVALID_IMAGE: "Please select valid format",
  IDENTITY_DOC_UPLOAD_SUCCESS: "Identity document uploaded successfully",
  ADDRESS_DOC_UPLOAD_SUCCESS: "Residential document uploaded successfully",
  NOT_APPLY: "Not Apply",
  VERIFIED: "Verified",
  NOT_VERIFIED: "Not Verified",
  REJECTED: "Rejected",
  BASIC: "Basic",
  ADVANCED: "Advanced",
  PRO: "Pro",
  APPROVED: "Approved",
  BASIC_PENDING: "Basic Pending",
  BASIC_SUBMITTED: "Basic Submitted",
  SUBMITTED: "Submitted",
  BASIC_VERIFIED: "Basic Verified",
  ADVANCED_Pending: "Advanced Pending",
  PRO_PENDING: "Pro Pending",
  BASIC_USER: "Basic User",
  ADVANCED_USER: "Advanced User",
  BUSINESS_ACCOUNT: "Business Account",
  TYPE_BASIC_DESCRIPTION1: "Crypto & fiat deposits up to 500,000€ monthly",
  TYPE_BASIC_DESCRIPTION2: "Crypto & fiat withdraws up to 500,000€ monthly",
  TYPE_BASIC_DESCRIPTION3: "Trade crypto and fiat spot markets",
  TYPE_BASIC_DESCRIPTION4: "Daily withdraw limits: 50,000€",
  DEFAULT_BANK_ACCOUNT_DETAIL:
    "Bank Account Proof (only default withdraw bank)",
  USER_ID: "User ID",
  MEMBER_SINCE: "Member Since",
  VERIFICATION_TYPE: "Verification Type",
  EMAIL_PHONE: "Email Address & Mobile",
  VERIFY_NOW: "Verify Now",
  PENDING: "Pending",
  WITHDRAW_ACCOUNT: "Withdraw Account",
  FIAT_WITHDRAW_DESCRIPTION1: "Minimal Withdraw",
  FIAT_WITHDRAW_DESCRIPTION2:
    "Withdraw will take 24-48 hrs due to bank network / holidays",
  FINAL_WITHDRAW_AMOUNT: "Final Withdraw Amount",
  WITHDRAW_AMOUNT: "Withdraw Amount",
  WITHDRAW_ADDRESS: "Withdraw Address",
  ALLOW_NUMERIC: "Only allowed numeric",
  BALANCE: "Balance",
  INSUFFICIENT_BALANCE: "Insufficient balance",
  KYC_SUBMIT_ALERT: "Please submit your kyc details",
  KYC_SUBMIT_ALERT_1: "Checks during the processing of your document",
  INVALID_BANK_ACCOUNT: "Invalid bank account",
  VERIFICATION_LINK: "Verification link sent to email address",
  INVALID_TOKEN: "Invalid Token",
  EXPIRY_TOKEN: "Expiry token",
  DEPOSIT_QR_CODE: "To deposit use this QR",
  COIN_ADDRESS: "Your Coin Address",
  COPY_ADDRESS: "Copy Address",
  COPY_BANK_INFO: "Copy Bank Info",
  COIN_DEPOSIT_DESCRIPTION1:
    "Send only {{CURRENCY_SYMBOL}} to this deposit address.",
  COIN_DEPOSIT_DESCRIPTION2: "Minimal Deposit: 0.001 LTC",
  COIN_DEPOSIT_DESCRIPTION3:
    "Sending coin or token other than {{CURRENCY_SYMBOL}} to this address may result in the loss of your deposit.",
  WITHDRAW_TOO_LOW: "Final amount too low",
  FIAT_DEPOSIT_DESCRIPTION1: "Minimal Deposit: €10",
  FIAT_DEPOSIT_DESCRIPTION2:
    "Must upload proof of deposit in pdf / jpg and enter reference number / ID",
  ADMIN_ACCOUNT: "Admin Account",
  DEPOSIT: "Deposit",
  DEPOSIT_AMOUNT: "Deposit Amount",
  TRANSACTION_ID: "Transaction ID",
  UPLOAD_PROOF: "Upload Proof",
  DEPOSIT_REQUEST_SUCCESS: "Deposit request sent successfully",
  DEPOSIT_TOO_LOW: "Deposit amount too low",
  SELECT_CURRENCY: "Select Currency",
  INVALID_WALLET_TYPE: "Invalid wallet type",
  WALLET_MIS_MATCH: "From wallet and to wallet should be different",
  WALLET_TRANSFER_SUCCESS: "Wallet conversion process completed successfully",
  OPEN_ORDER: "Open Orders",
  CANCEL: "Cancel",
  PRICE: "Price",
  STOP_LIMIT: "Stop-limit",
  STOP_MARKET: "Stop-market",
  TRAILING_STOP: "Trailing-stop",
  DOB: "Date Of Birth",
  "DEST_TAG_ID": "Destination Tag Id",
  "COPY_DEST_TAG_ID": "Copy Destination Tag Id",

  // Setting
  DEFAULT_THEME: "Default Theme for Trading",
  LIGHT_THEME: "Light Theme",
  DARK_THEME: "Dark Theme",

  // General Settings
  GENERAL_SETTINGS: "General Settings",

  // Wallet Page
  YOUR_BALANCE: "Your Balance",
  ESTIMATED_VALUE: "Estimated Value:",
  TOTAL_BALANCE: "Total Account Balance",
  TRANSFER_FUND: "Transfer funds",
  AMOUNT: "Amount",

  // Home Page
  WELCOME_TO: "Welcome to",
  BANNER_DESCRIPTION_1: `We put the power in your hands to `,
  BANNER_DESCRIPTION_2: "buy, sell and trade digital currency.",
  GET_STARTED: "Get Started",
  EXPLORE_MARKETS: "Explore Our Markets",
  EXPLORE_MARKETS_DESCRIPTION_1:
    "The World's Leading WHITE CRYPTO Currency Exchange",
  EXPLORE_MARKETS_DESCRIPTION_2:
    "Trade Bitcoin, ETH, and hundreds of other WHITE CRYPTO Currencies in minutes.",
  BUY: "Buy",

  //UPI
  REGISTER_UPI_ACCOUNT:
    "You have not registered any UPI ID. Please provide an UPI ID in order to withdraw funds",
  REGISTER_QR_ACCOUNT:
    "You have not registered any GPay QR code. Please provide QRCode in order to withdraw funds",
  ADD_QR_ACCOUNT: "Add QR Code",
  QR_ACCOUNT: "GPay Account",
  QR_NAME: "QR Code",
  ADD_UPI_ACCOUNT: "Add UPI ID",
  UPI_ACCOUNT: "UPI Account",
  UPI_NAME: "UPI ID",
  MOBILE_COMPLETE:"Please Complete Your Mobile Verification Process",
  EMAIL_COMPLETE:"Please Complete Your E-Mail Verification Process"
};
